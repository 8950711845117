import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ToastPlugin from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-bootstrap.css';

// import('../postcssrc')
import('@/assets/styles/global.scss')
router.beforeEach((to, from, next) => {
    /* 在路由发生变化的时候修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})
const app = createApp(App)
app.use(router)
app.use(ToastPlugin);
app.mount('#app')
