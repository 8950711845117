import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/register',
    meta:{
      title:"小商大户"
    }
  },
  {
    name: 'login',
    path: '/register',
    component: () => import('@/views/login.vue'),
    meta:{
      title:"登录注册"
    }
  },
  {
    name: 'download',
    path: '/download',
    meta:{
      title:'下载'
    },
    component: () => import('@/views/download.vue')
  },
  {
    name: 'Qrcode',
    path: '/qrcode',
    component: () => import('@/views/qrcode.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
