<template>
  <router-view/>
</template>

<style lang="scss">
@import "./assets/styles/global.scss";
@import "./assets/styles/flex.scss";

#app{
  height: 100vh;
}
</style>
